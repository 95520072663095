<template>
  <footer id="main-footer">
    <div class="container">
      <p class="copyright">© {{ new Date().getFullYear() }}, <a href="https://shtab.net" target="_blank" rel="noreferrer">Проєкт Антикорупційного штабу</a></p>
      <ul class="socials-list">
        <li><a href="https://www.facebook.com/shtab.net" class="social-icon icon-white facebook-icon" target="_blank" rel="noreferrer"/></li>
        <li><a href="https://www.instagram.com/anticor_shtab/" class="social-icon icon-white instagram-icon" target="_blank" rel="noreferrer"/></li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>