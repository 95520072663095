<template>
  <div class="mobile-menu">
    <div class="mobile-hamburger" @click="open"/>
    <div id="mobile-menu-wrap" :class="{opened}">
      <div class="mobile-menu-top">
        <div class="logo">
          <img :src="logo" :alt="SITE_NAME">
        </div>
        <div class="mobile-menu-close" @click="close"/>
      </div>
      <ul class="mobile-nav">
        <li @click="close"><router-link to="/about">Про Карту</router-link></li>
        <li @click="close"><router-link to="/lost">Можливо втрачені дози</router-link></li>
        <li @click="close"><a href="https://shtab.net/pages/view/donate" target="_blank" rel="noreferrer">Підтримати</a></li>
        <li @click="close"><a href="https://shtab.net/pages/view/contacts" target="_blank" rel="noreferrer">Контакти</a></li>
      </ul>
      <div class="mobile-menu-bottom">
        <div class="statistic">
          <div class="total-stat">Всього вакциновано: <span class="stat-amount">{{new Intl.NumberFormat().format(this.stat.total_full)}}</span></div>
          <div class="sars-stat">
            <span class="vac-name">AstraZeneca</span> (вакциновано: <span class="stat-amount">{{new Intl.NumberFormat().format(this.stat.total)}}</span>, <span>*можливо втрачено:</span> <span class="stat-amount">{{new Intl.NumberFormat().format(this.stat.fuckedup)}}</span>)
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-menu-overlay" :class="{opened}" @click="close"/>
  </div>
</template>

<script>
import {SITE_NAME} from "../config";
import logo from '../assets/img/logo.svg';

export default {
  props: ['stat'],
  name: "MobileMenu",
  data() {
    return {
      SITE_NAME,
      logo,
      opened: false
    }
  },
  methods: {
    open() {
      this.opened = true;
    },
    close() {
      this.opened = false;
    }
  }
}
</script>