import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: '/',
    routes: [
        {
            path: "/",
            component: () => import("@/views/Home"),
        },
        {
            path: "/about",
            component: () => import("@/views/About"),
        },
        {
            path: "/lost",
            component: () => import("@/views/Lost"),
        }
    ]
});