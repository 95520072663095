<template>
  <header id="main-header">
    <div class="container">
      <div class="header-left">
        <div class="logo">
          <router-link to="/"><img :src="logo" :alt="SITE_NAME"></router-link>
        </div>
        <!--<div class="other-projects">
          <a href="https://map.shtab.net/" class="project-link" target="_blank" rel="noreferrer" v-tooltip.bottom-start="repairsTooltip"><img :src="repairsMapLogo" alt="Карта ремонтів - знайди свій будинок!"></a>
          <a href="https://covid.shtab.net/" class="project-link" target="_blank" rel="noreferrer" v-tooltip.bottom-start="covidTooltip"><img :src="covidMapLogo" alt="Карта закупівель COVID-19"></a>
        </div>-->
      </div>
      <div class="header-right">
        <nav id="main-nav">
          <ul>
            <li><router-link to="/about">Про Карту</router-link></li>
            <li><router-link to="/lost">Можливо втрачені дози</router-link></li>
            <li><a href="https://shtab.net/pages/view/donate" target="_blank" rel="noreferrer">Підтримати</a></li>
            <li><a href="https://shtab.net/pages/view/contacts" target="_blank" rel="noreferrer">Контакти</a></li>
          </ul>
        </nav>
        <div class="statistic">
          <div class="total-stat">Всього вакциновано: <span class="stat-amount">{{new Intl.NumberFormat().format(this.stat.total_full)}}</span></div>
          <div class="sars-stat">
            <span class="vac-name">AstraZeneca</span> (вакциновано: <span class="stat-amount">{{new Intl.NumberFormat().format(this.stat.total)}}</span>, <span v-tooltip.bottom-end="fuckedUpTooltip">*можливо втрачено:</span> <span class="stat-amount">{{new Intl.NumberFormat().format(this.stat.fuckedup)}}</span>)
          </div>
        </div>
        <MobileMenu :stat="stat"/>
      </div>
    </div>
  </header>
</template>

<script>
import Vue from 'vue';
import VTooltip from 'v-tooltip';
import MobileMenu from "./MobileMenu";

import {API_URL, SITE_NAME} from "../config";

import logo from '../assets/img/logo.svg';
import repairsMapLogo from '../assets/img/remont-map-logo.svg';
import covidMapLogo from '../assets/img/covid-map-logo.svg';

Vue.use(VTooltip);

export default {
  components: {
    MobileMenu
  },
  name: "Header",
  data() {
    return {
      stat: {
        total_full: 0,
        total: 0,
        fuckedup: 0
      },
      SITE_NAME,
      logo,
      repairsMapLogo,
      covidMapLogo,
      repairsTooltip: 'Карта ремонтів - знайди свій будинок!',
      covidTooltip: 'Карта закупівель COVID-19 - Перевір, чи не крадуть на масках в твоїй лікарні!',
      fuckedUpTooltip: 'вакцини AstraZeneca/Covishield та AstraZeneca-SKBio мають у одному флаконі 10 доз. Після відкриття багатодозові флакони слід використати протягом 6 годин. Усі відкриті багатодозові флакони препарату слід викинути наприкінці сеансу імунізації, або протягом 6 годин, залежно від того, що настане раніше. Аналітики ГО “Антикорупційний штаб” порахували, що якщо протягом одного дня провакцинували менше число заокруглене до 10, то ці рештки необхідно утилізовувати, тобто викидати.'
    }
  },
  mounted() {
    fetch(`${API_URL}stat`)
        .then(res => res.json())
        .then(json => this.stat = json);
  }
}
</script>