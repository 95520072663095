<template>
  <div id="app">
    <Header/>
    <main id="main-content">
      <router-view></router-view>
    </main>
    <Footer/>
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
import './assets/scss/main.scss';

export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
}
</script>